.side-menu-wrapper {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100vh - 50px) !important;
  z-index: 100;
}

@media (min-width: 768px) {
  .side-menu-wrapper {
    top: 0;
    height: 100vh !important;
  }
}

.side-menu-wrapper.not-mobile {
  z-index: 0;
  width: 150px;
}

.side-menu-dimmer {
  height: 100%;
  background: rgba(0, 0, 0, .4) !important;
  transition: opacity .2s ease-in !important;
}

.not-visible {
  pointer-events: none;
}

.not-visible .side-menu-dimmer {
  opacity: 0;
}

.side-menu-wrapper .ui.vertical.menu {
  display: flex !important;
  border-top: none !important;
  border-left: none !important;
  border-bottom: none !important;
}

.side-menu-wrapper .ui.vertical.sidebar.menu>.item:first-child:before {
  display: none !important;
}

.side-menu-wrapper .ui.sidebar.menu .item:nth-last-of-type(2) {
  margin-top: auto;
}

.side-menu-wrapper .ui.sidebar.menu .item:nth-last-of-type(-n+2):before {
  top: 0;
  bottom: auto;
}

.side-menu-wrapper .ui.vertical.menu .item > .menu {
  margin-top: 1em;
}

.side-menu-wrapper .ui.vertical.menu .item>.menu:after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background: rgba(34,36,38,.1);
}

.side-menu-wrapper .ui.vertical.menu .item .menu .item {
  padding: .92857143em 1.14285714em;
  font-size: unset;
  text-indent: 1.25em;
}

.side-menu-wrapper .ui.vertical.menu .item .menu .item:last-of-type {
  margin-bottom: -.92857143em;
}

.side-menu-wrapper .ui.vertical.menu .item .menu .active.item {
  background-color: rgba(0, 0, 0, .05);
}
