.images-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 -15px;
}

.actions .ui.placeholder {
  position: relative;
}

.actions .ui.placeholder .upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

/*.upload-zone {
  position: relative;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.upload-zone-input {
  border: 2px dashed #aaa;
}

.upload-zone-input:hover {
  border-color: #777;
  background: #eee;
}*/

