.select-image-preview {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.select-image-preview--fluid {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-preview-img {
  width: 100%;
  object-fit: contain;
}

.image-preview-change {
  position: relative;
}

.ui.button.image-preview-copy {
  position: absolute;
  bottom: 4px;
  left: 4px;
}

i.icon.image-preview-delete {
  position: absolute;
  top: -13px;
  right: -15px;
}

