.ui.form-actions {
  margin-top: 30px;
  background-color: #fff !important;
}

.ui.form-actions .ui.button {
  background-color: #fff !important;
}

.ui.borderless.tabular.menu .item.menu-header {
  flex-grow: 1;
  align-items: center;
  text-align: center;
}

.ui.borderless.tabular.menu .item.menu-header h3 {
  margin: 0 auto;
}

.ui.back-button {
  float: left !important;
}

@media (min-width: 768px) {
  .ui.back-button {
    margin-right: 20px !important;
    margin-bottom: 10px !important;
  }
}

.ui.borderless.tabular.menu {
  position: relative;
}

.ui.borderless.tabular.menu:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: -20px;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.ql-editor {
  min-height: 200px;
}

.ui.input > .ui.label {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -1px 0 0;
  padding: 0 15px;
  font-size: 14px;
  font-weight: 700;
  border-radius: .28571429rem 0 0 .28571429rem;
}

