.chart-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.chart-wrapper {
  position: relative;
  margin-bottom: 10px;
}

.charts-legend {
  width: 100%;
  max-width: 500px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.report-chart {
  margin: auto;
}

.total-label {
  position: absolute;
  top: 0;
  left: 0;
  height: 260px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}
