.layout {
  position: relative;
  margin: 40px auto;
  max-width: 678px;
}

.layout .carousel__slider {
  margin-bottom: 30px;
}

.layout .carousel__slide {
  padding: 20px !important;
}

.layout .carousel__slide.active {
  border: 1px solid #1c7aca;
  border-radius: 4px;
}

.layout .button-prev,
.layout .button-next {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}


.layout .button-prev {
  left: -40px;
}

.layout .button-next {
  right: -40px;
}

.ui.placeholder.segment .button-prev {
  left: 25px;
}

.ui.placeholder.segment .button-next {
  right: 25px;
}

.layout .carousel__inner-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout .carousel__inner-slide img {
  margin: auto;
  cursor: pointer;
}

.layout-scene {
  position: relative;
}

.layout .ui.label.layout-label {
  position: absolute;
  transform: translate(-50%, 0);
  margin-left: 15px;
  flex-grow: 0 !important;
}

.layout-scene .layout-preview {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s;
  pointer-events: none;
}

.layout-scene .layout-preview.loaded {
  visibility: visible;
  opacity: 1;
}

.layout-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 379px;
  /*pointer-events: none;*/
}

.layout-banner {
  position: absolute;
  background-color: #fff;
  border-radius: 5px;
}

.ui.grid.layout-pickers .field {
  position: relative;
}

.chrome-picker {
  position: absolute;
  z-index: 2;
  top: 65px;
  left: -15px;
}

.layout-color {
  display: inline-flex;
  flex-shrink: 0 !important;
  width: 42px;
  height: 43px;
  border-radius: .28571429rem;
  border: 1px solid rgba(34,36,38,.15);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding: 10px;
  background-clip: content-box;
}

.layout-banner .placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: #666;
}


.layout-banner .ui.label.pointing {
  position: absolute;
  top: -50px;
  left: 50%;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, 0);
}

.layout-banner:hover .ui.label.pointing {
  visibility: visible;
  opacity: 1;
}

.banners-list .ui.header {
  display: flex;
  align-items: center;
}

.banners-list .ui.header .sub.header {
  display: inline;
  margin: 0 0 0 20px;
  padding: 0;
}
.banners-list .ui.header .sub.header.subheader {
  margin-left: auto;
}

.banners-list .ui.divider {
  margin: 10px 0;
  padding: 0 !important;
}

.layout-banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

