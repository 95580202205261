.select-file-preview {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.select-file-preview--fluid {
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-preview-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
  cursor: pointer;
}

.file-preview-change {
  position: relative;
}

.ui.button.file-preview-copy {
  position: absolute;
  bottom: 4px;
  left: 4px;
}

i.icon.file-preview-delete {
  position: absolute;
  top: -13px;
  right: -15px;
}

