.ui.sortable.table thead th {
  padding: .5em .75em;
}

/*.ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 70px;
  z-index: 2;
}
*/

.sponsors .ui.tabular.menu,
.sponsor .ui.tabular.menu {
  position: relative;
}

.sponsors .ui.tabular.menu:before,
.sponsor .ui.tabular.menu:before {
  content: '';
  position: absolute;
  top: -20px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #fff;
}

.sponsors .ui.segment {
  /*margin-bottom: 10px;*/
  padding: 12px;
  border-bottom: 1px solid rgba(0,0,0,.1) !important;
}

.sponsors > .ui.segment {
  border-top: 1px solid rgba(0,0,0,.1) !important;
}
.sponsors > [data-rbd-draggable-context-id] {
  cursor: default !important;
}

.sponsors .ui.accordion > .ui.header {
  margin: 0;
  /*padding: 15px;*/
}
.sponsors .ui.accordion .ui.header .icon:not(.dropdown) {
  float: right !important;
  box-sizing: content-box;
  margin: -.25em 0 0;
  padding: 10px;
  font-size: .85em;
}

.sponsors .ui.accordion .ui.header .icon.delete {
  cursor: pointer;
}

.sponsors .ui.accordion:not(.styled) .accordion .title~.content:not(.ui),
.sponsors .ui.accordion:not(.styled) .title~.content:not(.ui) {
  padding-left: 27px;
  padding-right: 27px;
  /*padding-bottom: 40px;*/
}

.ui.red.tiny.basic.delete {
  box-shadow: none !important;
  border: none !important;
}

.ui.input input[type=number][name*="phone"]::-webkit-inner-spin-button,
.ui.input input[type=number][name*="phone"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

a[data-disabled="true"] {
  pointer-events: none;
  opacity: 0.75;
}

.ui.accordion .title.active {
  position: relative;
}

.ui.accordion .title.active .ui.button {
  position: absolute;
  top: 0;
  right: 37px;
}

td.expandCell {
  /*padding: 0 10px 0 4px !important;*/
  border-top: none !important;
  color: #c0c0c0;
  cursor: pointer;
  text-align: center !important;
  width: 50px !important;
}

td.expandCell i {
  display: block;
  margin: auto;
}

td.expandCell:hover {
  color: #666666;
}
