.files-list:hover {
  cursor: pointer;
}

.file-preview {
  position: relative;
  height: 200px;
}

i.icon.file-preview-delete {
  position: absolute;
  top: -13px;
  right: -15px;
  cursor: pointer;
}

