.login-page {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100vh;
    padding: 20px 10%;
}

.login-form {
    margin: auto;
    width: 100%;
    max-width: 400px !important;
}
