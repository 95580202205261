.top-menu {
  height: 50px;
  width: 100%;
  border-radius: 0 !important;
  margin: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  border-top: none !important;
  border-right: none !important;
  border-left: none !important;
}

.top-menu.not-mobile {
  display: none;
}

.top-menu .item {
  border-radius: 0 !important;
}

.top-menu__title {
  flex-grow: 1 !important;
  padding-right: 44px;
  display: flex;
  justify-content: center;
  font-weight: bold !important;
}
