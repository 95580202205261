.sponsors .ui.mini.basic.buttons .ui.button {
  margin: -1px;
}

.sponsors .ui.large.clearing.vertical.segment .row {
  min-height: 118px;
}

.sponsors .ui.large.clearing.vertical.segment .row {
  min-height: 118px;
}

.sponsors .ui.secondary.center.aligned.segment {
  height: 90px;
}

/*.sponsor:last-child {
  border-bottom: 1px solid rgba(34,36,38,.15) !important;
}

.sponsor__row {
  display: flex;
  width: 100%;
  align-items: center;
}

.sponsor__avatar {
  margin: 0 20px 0 0;
  width: 120px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8e8e8;
}

.sponsor__details {
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  flex-grow: 1;
}

.sponsor__name {
  font-weight: bold;
}

.sponsor__status {
  position: relative;
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  margin: auto 20px;
}

.sponsor__status > .ui.label {
  width: 80px;
}

.sponsor__status > .dropdown.icon {
  position: absolute;
  top: 1px;
  right: 22px;
  color: rgba(0,0,0,.6);
}

.sponsor__contents {
  margin: 0 auto;
  flex-grow: 1;
}

.sponsor__contents > .ui.label {
  margin-bottom: 5px;
}

.sponsor__edit {
  margin: 0 0 0 auto !important;
}
*/
