html {
  touch-action: manipulation;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding-top: 50px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (min-width: 768px) {
  body {
    padding-top: 0;
  }
}

code {
  font-family: Consolas, 'Courier New', monospace;
}

.cloack {
  visibility: visible;
}

.page-scroller {
  width: 100%;
  max-width: 1180px;
  min-height: 100vh;
  padding: 0 10px;
  margin: auto 0;
}

@media (min-width: 768px) {
  .page-scroller {
    padding: 20px;
  }
}

.ui.inverted.dimmer {}

.page-scroller.not-mobile {
  padding-left: 170px;
}

.divider.fitted.hidden {
  margin-top: 5px;
}

/*.draggable-item {
  padding-top: 10px;
}*/

.error-popup {
  position: fixed !important;
  z-index: 100;
  top: 60px;
  left: 10%;
  width: 80%;
}

#refresh-popup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  background: #333;
  font-size: 18px;
  color: white;
  visibility: hidden;
  border-radius: 5px;
  box-shadow: 1px 1px 5px #333;
  padding: 15px 20px;
}

#refresh-button {
    text-transform: uppercase;
    margin-top: 10px;
}

#refresh-popup.visible-popup {
  visibility: visible;
}


.list-segment:last-child {
  border-bottom: 1px solid rgba(34,36,38,.15) !important;
}

.list-segment {
  display: flex;
  align-items: center;
}

.filters {
  margin-left: auto !important;
}

.list-segment__row {
  display: flex;
  width: 100%;
  align-items: center;
}

.list-segment__avatar {
  margin: 0 20px 0 0;
  width: 60px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 60px !important;
  min-height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.05);
  border: 1px solid rgba(34,36,38,.1);
  border-radius: 4px;
}

.list-segment__logo {
  margin: 0 20px 0 0;
  width: 180px;
  flex-grow: 0;
  flex-shrink: 0;
  height: 60px !important;
  min-height: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0,0,0,.05);
  border: 1px solid rgba(34,36,38,.1);
  border-radius: 4px;
}

.list-segment__logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.list-segment__details {
  display: flex;
  flex-direction: column;
  flex-basis: 20%;
  flex-grow: 1;
}

.list-segment__name {
  font-weight: bold;
}

.list-segment__status {
  position: relative;
  display: flex !important;
  align-items: center !important;
  flex-wrap: nowrap !important;
  margin: auto 20px;
}

.list-segment__status > .ui.label {
  width: 80px;
}

.list-segment__status > .dropdown.icon {
  position: absolute;
  top: 1px;
  right: 22px;
  color: rgba(0,0,0,.6);
}

.list-segment__contents {
  margin: 0 auto;
  flex-grow: 1;
}

.list-segment__contents > .ui.label {
  margin-bottom: 5px;
}

.list-segment__edit {
  margin: 0 0 0 auto !important;
}


.ui.label > em {
  font-style: normal;
  color: #989898;
}

.filters-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ui.dimmer + .ui.dimmer {
  background: none !important;
}


.visually-hidden {
  opacity: 0;
  visibility: hidden;
}

.ui.segment, .ui.segments .segment {
  /*font-size: unset;*/
}

h1, h2, h3, h4, h5, h6, label {
  /*font-weight: normal !important;*/
  /*color: #545454 !important*/
}

.ui.menu .ui.button {
  /*margin-top: 8px;*/
  /*padding-top: 11px;*/
  height: 35px !important;
  font-size: 13px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.ui.basic.icon.button.clear {
  position: absolute;
  top: 13px;
  right: 0;
  background: none !important;
  border: none !important;
  box-shadow: none !important;
}

.error.field {
  position: relative;
}

.ui.form .field {
  position: relative;
}

.ui.form .field .prompt.label {
  position: absolute;
  z-index: 1;
  left: 50%;
  font-size: 11px;
  transform: translate(-50%, 0);
  color: rgba(0,0,0,.6) !important;
  border: 1px solid rgba(34,36,38,.15) !important;
  box-shadow: none !important;
}

.ui.form .field .prompt.label.below {
  top: -9px;
}

.ui.form .field .prompt.label.above {
  bottom: -30px;
  white-space: nowrap;
}

.field .ui.pointing.label:before {
  border-color: rgba(34,36,38,.15) !important;
}

.ui.form .field.error .ui.dropdown, .ui.form .field.error .ui.dropdown .item, .ui.form .field.error .ui.dropdown .text, .ui.form .fields.error .field .ui.dropdown, .ui.form .fields.error .field .ui.dropdown .item {
  background-color: #fff !important;
}

.ui.grid > .row > .stretched.column > .ui.checkbox label {
  flex-grow: 0;
  display: inline;
}

.ui.selection.dropdown.username-dropdown {
  position: relative;
  min-height: 0;
  padding: 0;
  top: -15px;
  border-radius: 4px 0 0 4px;
}

.ui.input input[readonly] {
  cursor: default;
  opacity: 0.85;
  cursor: not-allowed;
}

[data-domain]:after {
  content: attr(data-domain);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 215px;
  padding: .78571429em;
  font-weight: bold;
  background-color: #fff;
  border: 1px solid rgba(34,36,38,.15);
  line-height: 14px;
  border-radius: .28571429rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.ui.input[data-domain] input {
  padding-right: 230px;
}

.ui.input[data-domain] .icon.spinner {
  right: 215px;
}

.saml-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.saml-message {
  margin: auto;
}

.images-gallery .ui.card {
  width: calc(50% - 1.5em) !important;
}

@media (min-width: 768px) {
  .images-gallery .ui.card {
    width: calc(25% - 1.5em) !important;
  }
}

.images-gallery .ui.card > .image {
  height: 0 !important;
  overflow: hidden;
  padding-bottom: 100% !important;
}

.images-gallery .ui.card > .image > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.images-gallery .ui.card > .extra.content {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.images-gallery .ui.card > .extra.content b {
  display: block;
  line-break: anywhere;
}

svg path {
  transition: fill .15s;
}
