.select-image-input {
  position: relative;
  margin-bottom: 1em;
}

.select-image-input label {
    width: 100%;
}

.select-image-input-error label {
  color: #9f3a38 !important;
}

.error.field .ui.placeholder {
  color: #9f3a38 !important;
  border-color: #e0b4b4 !important;
}
